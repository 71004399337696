import React, { useState } from 'react';
import { CheckSquare, ExternalLink } from 'lucide-react';
import { Worker } from '../types/game';
import { WORKER_TYPES } from '../utils/workerTypes';
import WebApp from '@twa-dev/sdk';

interface Task {
  id: string;
  title: string;
  description: string;
  link: string;
  reward: Worker;
  completed: boolean;
  verificationUrl: string;
}

interface TasksPageProps {
  onClaimReward: (worker: Worker) => void;
}

export function TasksPage({ onClaimReward }: TasksPageProps) {
  const [tasks, setTasks] = useState<Task[]>([
    {
      id: 'telegram',
      title: 'Join Telegram Channel',
      description: 'Join our official Telegram channel to stay updated',
      link: 'https://t.me/EMSXM',
      verificationUrl: 'https://t.me/EMSXM',
      reward: {
        id: `reward_telegram_${Date.now()}`,
        type: 'emsx',
        level: 5,
        position: -1,
        miningRate: 0,
        stats: WORKER_TYPES.emsx.stats,
      },
      completed: false,
    },
    {
      id: 'twitter',
      title: 'Follow on Twitter',
      description: 'Follow EMSX official Twitter account',
      link: 'https://x.com/EMSX_M',
      verificationUrl: 'https://x.com/EMSX_M',
      reward: {
        id: `reward_twitter_${Date.now()}`,
        type: 'emsx',
        level: 5,
        position: -1,
        miningRate: 0,
        stats: WORKER_TYPES.emsx.stats,
      },
      completed: false,
    },
  ]);

  const verifyTask = async (taskId: string): Promise<boolean> => {
    const task = tasks.find(t => t.id === taskId);
    if (!task) return false;

    try {
      // For Telegram channel verification
      if (taskId === 'telegram') {
        return await new Promise((resolve) => {
          WebApp.openTelegramLink(task.verificationUrl);
          WebApp.showPopup({
            title: 'Verification Required',
            message: 'Please join our Telegram channel and click "Joined" to verify.',
            buttons: [
              {
                id: 'joined',
                type: 'ok'
              },
              {
                type: 'cancel'
              }
            ]
          }, (buttonId) => {
            resolve(buttonId === 'joined');
          });
        });
      }
      
      // For Twitter verification
      if (taskId === 'twitter') {
        return await new Promise((resolve) => {
          window.open(task.verificationUrl, '_blank');
          WebApp.showPopup({
            title: 'Verification Required',
            message: 'Please follow our Twitter account and click "Followed" to verify.',
            buttons: [
              {
                id: 'followed',
                type: 'ok'
              },
              {
                type: 'cancel'
              }
            ]
          }, (buttonId) => {
            resolve(buttonId === 'followed');
          });
        });
      }
    } catch (error) {
      console.error('Task verification failed:', error);
      return false;
    }

    return false;
  };

  const handleTaskComplete = async (taskId: string) => {
    const isVerified = await verifyTask(taskId);
    
    if (isVerified) {
      setTasks(prev => prev.map(task => {
        if (task.id === taskId && !task.completed) {
          onClaimReward(task.reward);
          return { ...task, completed: true };
        }
        return task;
      }));

      WebApp.showPopup({
        title: 'Success!',
        message: 'Task completed! You received a Level 5 EMSX Miner.',
        buttons: [{ type: 'ok' }]
      });
    }
  };

  return (
    <div className="p-4 space-y-4">
      <div className="bg-gradient-to-br from-green-500/20 to-blue-500/20 rounded-3xl p-6 border border-white/10">
        <div className="flex items-center gap-4 mb-6">
          <div className="bg-green-500/20 rounded-2xl p-3 border border-green-500/30">
            <CheckSquare className="w-6 h-6 text-green-400" />
          </div>
          <div>
            <h2 className="text-2xl font-bold text-white">Daily Tasks</h2>
            <p className="text-green-300">Complete tasks to earn rewards</p>
          </div>
        </div>

        <div className="space-y-4">
          {tasks.map((task) => (
            <div
              key={task.id}
              className="bg-white/5 rounded-2xl p-4 border border-white/10"
            >
              <div className="flex items-start justify-between gap-4">
                <div className="flex-1">
                  <h3 className="text-lg font-semibold text-white mb-1">
                    {task.title}
                  </h3>
                  <p className="text-sm text-gray-400 mb-3">
                    {task.description}
                  </p>
                  <div className="flex items-center gap-2">
                    <a
                      href={task.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm text-blue-400 hover:text-blue-300 flex items-center gap-1"
                    >
                      Open Link
                      <ExternalLink className="w-3 h-3" />
                    </a>
                    <div className="text-sm text-purple-400">
                      Reward: Level {task.reward.level} EMSX Miner
                    </div>
                  </div>
                </div>
                <button
                  onClick={() => handleTaskComplete(task.id)}
                  disabled={task.completed}
                  className={`px-4 py-2 rounded-xl text-sm font-medium
                    ${task.completed
                      ? 'bg-gray-500/20 text-gray-400 cursor-not-allowed'
                      : 'bg-green-500 hover:bg-green-600 text-white'
                    } transition-colors`}
                >
                  {task.completed ? 'Completed' : 'Claim Reward'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}